import React from 'react'
import Layout from '../components/layout'
import { graphql} from 'gatsby'
import { Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper";
import HorseSlide from '../components/horseSlide/horseSlideView'
import useWindowDimensions from '../hooks/useWindowDimensions'





const TarievenIndex = (props) => {

  let slidesAmount = 3
  const {width} = useWindowDimensions();
  if (width > 1200){
    slidesAmount = 3;
  }
  else{
    slidesAmount = 1;
  }

  const horses = props.data.allContentfulVerkooppaardenPage.nodes[0].verkoopPaarden;


    return (
      <Layout title={props.data.allContentfulVerkooppaardenPage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>{props.data.allContentfulVerkooppaardenPage.nodes[0].title}</h1>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='swiper_container' >
            <Swiper
            slidesPerView={slidesAmount}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            >
              {(horses).map((horse) => <SwiperSlide><HorseSlide heading={horse.heading} subHeading={horse.subHeading} description={horse.shortDescription}  image={horse.image?.gatsbyImageData} slug={horse.slug} ></HorseSlide></SwiperSlide>) }
            </Swiper>
          </div>
        </div>
      </Layout>
    )
  }


export default TarievenIndex


export const PageQuery = graphql`
query VerkooppaardenQuery {
  allContentfulVerkooppaardenPage {
    nodes {
      title
      verkoopPaarden {
        slug
        heading
        subheading
        shortDescription
        image {
          gatsbyImageData(width: 300, height: 300)
        }
      }
    }
  }
}
`


